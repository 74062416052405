<template>
  <div class="teamReportView">
    <van-sticky>
      <van-nav-bar :title="title[active]" left-arrow @click-left="onClickLeft" />
    </van-sticky>
    <van-tabs
      v-model="active"
      line-width="60px"
      background="#151d31"
      title-inactive-color="#ffffff"
      title-active-color="#4087f1"
    >
      <van-tab :title="$t('团队报表')">
        <div class="search">
          <div style="display: flex;align-items: center;">
            <van-field v-model="start_time" readonly @click="onShowStartDatePicker" />
            <span style="color:#ffffff;margin: 0 0.2rem;">--</span>
            <van-field v-model="end_time" readonly @click="onShowEndDatePicker" />
          </div>
          <van-button type="info" size="mini" @click="onSearch">{{ $t('搜索') }}</van-button>
        </div>
        <div class="total">
          <ul>
            <div class="databox">
              <li>
                <em>{{ $t('团队充值') }}(円)</em>
                <span v-text="teamInfo.team_recharge"></span>
              </li>
            </div>
            <div class="databox">
              <li>
                <em>{{ $t('团队提现') }}(円)</em>
                <span v-text="teamInfo.team_withdraw"></span>
              </li>
            </div>
          </ul>
          <ul>
            <div class="databox">
              <li>
                <em>{{ $t('首充人数') }}({{ $t('个') }})</em>
                <span v-text="teamInfo.firt_recharge"></span>
              </li>
            </div>
            <div class="databox">
              <li>
                <em>{{ $t('首推人数') }}({{ $t('个') }})</em>
                <span v-text="teamInfo.firt_tj_num"></span>
              </li>
            </div>
          </ul>
          <ul>
            <div class="databox">
              <li>
                <em>{{ $t('团队人数') }}({{ $t('个') }})</em>
                <span v-text="teamInfo.team_num"></span>
              </li>
            </div>
            <div class="databox">
              <li>
                <em>{{ $t('团队新增') }}({{ $t('个') }})</em>
                <span v-text="teamInfo.team_new_num"></span>
              </li>
            </div>
          </ul>
        </div>
        <van-tabs
          line-width="60px"
          background="#151d31"
          title-inactive-color="#ffffff"
          title-active-color="#4087f1"
          v-model="active2"
          @change="onChange"
        >
          <van-tab :title="$t('一级')"></van-tab>
          <van-tab :title="$t('二级')"></van-tab>
          <van-tab :title="$t('三级')"></van-tab>
          <van-list
            v-model="loading2"
            :finished="finished2"
            :finished-text="$t('没有更多了')"
            @load="onLoad2"
            loading-text="..."
          >
            <van-cell v-for="(item, key) in list2" :key="key" class="xiax_item">
              <div class="left">
                <span class="desc">{{ $t('用户') }}: {{ item.user }}</span>
                <span class="money">{{ $t('日期') }}: {{ item.reg_time }}</span>
              </div>
              <div class="right">
                <span class="time">{{ $t('直推人数') }}: {{ item.parentCount }}</span>
                <span class="status">{{ $t('团队人数') }}: {{ item.teamCount }}</span>
              </div>
            </van-cell>
          </van-list>
          <van-empty image="error" :description="$t('暂无数据')" v-show="!list2" />
        </van-tabs>
      </van-tab>
      <van-tab :title="$t('我的团队')">
        <div class="search">
          <div style="display: flex;align-items: center;">
            <van-field v-model="start_time" readonly @click="onShowStartDatePicker" />
            <span style="color:#ffffff;margin: 0 0.2rem;">--</span>
            <van-field v-model="end_time" readonly @click="onShowEndDatePicker" />
          </div>
          <van-button type="info" size="mini" @click="onSearch">{{ $t('搜索') }}</van-button>
        </div>
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('没有更多了')"
          @load="onLoad"
          loading-text="..."
        >
          <table width="100%">
            <thead>
              <tr>
                <th>{{ $t('用户') }}</th>
                <th>{{ $t('充值') }}</th>
                <th>{{ $t('提现') }}</th>
                <th>{{ $t('返点') }}</th>
                <th>{{ $t('提成') }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, key) in list" :key="key">
                <td v-text="item.user"></td>
                <td>
                  <em v-text="item.withdraw_money"></em>
                </td>
                <td v-text="item.pay_money"></td>
                <td>
                  <em v-text="item.team_money"></em>
                </td>
                <td v-text="item.tjj_money"></td>
              </tr>
            </tbody>
          </table>
        </van-list>
      </van-tab>
    </van-tabs>
    <van-popup v-model="showStartDatePicker" position="bottom" :style="{ height: '45%' }">
      <van-datetime-picker
        v-model="currentStartDate"
        :min-date="minDate"
        :title="$t('选择年月日')"
        type="date"
        @cancel="onStartCancel"
        @confirm="onStartConfirm"
      />
    </van-popup>
    <van-popup v-model="showEndDatePicker" position="bottom" :style="{ height: '45%' }">
      <van-datetime-picker
        v-model="currentEndDate"
        :min-date="minDate"
        :title="$t('选择年月日')"
        type="date"
        @cancel="onEndCancel"
        @confirm="onEndConfirm"
      />
    </van-popup>
  </div>
</template>
<script>
import { formatDate } from "@/utils/func";
import { getTeamInfo, getTeamReport, getTeamList } from "@/api/task";
export default {
  name: "DayReport",
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 15,
      loading: false,
      finished: false,
      title: [this.$t("团队报表"), this.$t("我的团队")],
      active: 0,
      start_time: this.$func.formatDate(new Date()),
      end_time: this.$func.formatDate(new Date()),
      currentStartDate: new Date(),
      currentEndDate: new Date(),
      showStartDatePicker: false,
      showEndDatePicker: false,
      minDate: new Date(2020, 0, 1),
      teamInfo: [],
      list2: [],
      page2: 1,
      pageSize2: 15,
      loading2: false,
      finished2: false,
      active2: 0
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.GetTeamInfo();
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onShowStartDatePicker() {
      this.showStartDatePicker = true;
    },
    onShowEndDatePicker() {
      this.showEndDatePicker = true;
    },
    onStartCancel() {
      this.showStartDatePicker = false;
    },
    onChange() {
      this.list2 = [];
      this.finished2 = false;
      this.page2 = 1;
    },
    onSearch() {
      if (this.active == 0) {
        this.GetTeamInfo();
      } else {
        this.list = [];
        this.finished = false;
        this.page = 1;
      }
    },
    //获取
    GetTeamInfo() {
      getTeamInfo({ start_time: this.start_time, end_time: this.end_time })
        .then(response => {
          this.teamInfo = response.result;
        })
        .catch(error => {});
    },
    GetTeamList() {
      getTeamList({
        page: this.page2,
        pageSize: this.pageSize2,
        level: this.active2
      })
        .then(response => {
          this.list2.push.apply(this.list2, response.result);
          // 加载状态结束
          this.loading2 = false;
          this.page2++;
          if (response.result.length == 0) {
            this.finished2 = true;
          }
        })
        .catch(error => {});
    },
    GetTeamReport() {
      getTeamReport({
        page: this.page,
        pageSize: this.pageSize,
        start_time: this.start_time,
        end_time: this.end_time
      })
        .then(response => {
          this.list.push.apply(this.list, response.result);
          // 加载状态结束
          this.loading = false;
          this.page++;
          if (response.result.length == 0) {
            this.finished = true;
          }
        })
        .catch(error => {});
    },
    onLoad() {
      this.GetTeamReport();
    },
    onLoad2() {
      this.GetTeamList();
    },
    onStartConfirm(value) {
      this.currentStartDate = value;
      this.start_time = this.$func.formatDate(value);
      this.showStartDatePicker = false;
      if (this.currentStartDate > this.currentEndDate) {
        this.currentEndDate = this.currentStartDate;
        this.end_time = this.$func.formatDate(this.currentEndDate);
      }
    },
    onEndCancel() {
      this.showEndDatePicker = false;
    },
    onEndConfirm(value) {
      this.currentEndDate = value;
      this.end_time = this.$func.formatDate(value);
      this.showEndDatePicker = false;
      if (this.currentStartDate > this.currentEndDate) {
        this.currentStartDate = this.currentEndDate;
        this.start_time = this.$func.formatDate(this.currentStartDate);
      }
    }
  }
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.teamReportView
  .van-tabs__line
    background-color: #4087f1
  .search
    display: flex
    align-items: center
    justify-content: space-between
    padding: 0.312rem
    .van-cell
      width: 2.8rem
      padding: 0.1rem 0.3rem
      font-size: 0.36rem
      line-height: 0.6rem
      background-color: #fff
      height: 0.7rem
      border-radius: 0.2rem
      .van-field__control
        color: #000000 !important
    .van-button
      border-radius: 0.2rem
      height: 0.7rem
  .van-picker
    background-color: #151d31
    .van-picker__toolbar
      color: #ffffff
    .van-picker-column__item
      color: #ffffff
    .van-picker__mask
      background-image: linear-gradient(180deg, rgba(21, 29, 49, 0.9), rgba(21, 29, 49, 0.4)), linear-gradient(0deg, rgba(21, 29, 49, 0.9), rgba(21, 29, 49, 0.4))
  .total
    margin-bottom: 0.3rem
    color: #4087f1
    font-weight: 600
    margin-top: 1px
    ul
      display: flex
      text-align: center
      font-size: 0.42rem
      line-height: 1
      margin-bottom: 1px
      .databox:last-child
        padding: 0
      .databox
        flex: 1
        padding-right: 1px
        li
          color: #4087f1
          background-color: #151d31
          flex-direction: column
          box-sizing: border-box
          padding: 0.5rem 0.2rem
          height: 100%
          em
            display: block
            line-height: 1
            margin-bottom: 0.3rem
            color: #bbb
            font-size: 0.4rem
            font-weight: 400
  .xiax_item
    background-color: #151d31
    margin-bottom: 0.15rem
    line-height: 0.6rem
    padding: 0.15rem 0.4rem
    span
      display: block
    .van-cell__value
      display: flex
      color: #c0c4cc
      align-items: center
      .left
        font-size: 0.36rem
        padding: 6px
        .desc
          font-weight: 500
        .money
          color: $specColor
          font-weight: bold
          font-size: 0.36rem
      .right
        flex: 1
        font-size: 0.34rem
        padding: 6px
        text-align: right
        .time
          font-weight: 450
  table
    border-collapse: collapse
    border-spacing: 0
    background-color: #151d31
    text-align: center
    margin-bottom: 0.6rem
    font-weight: 500
    tr
      th
        padding: 0.375rem 0
        font-size: 0.36rem
        color: #bbb
        width: 20%
      td
        border: 0.03rem solid #0e1526
        padding: 0.3rem 0.15rem
        color: #fff
        em
          color: #4087f1
      td:first-child, td:last-child
        border-left: 0
</style>
